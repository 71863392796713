<template>
  <div class="ative-list-container">
    <div class="content-container">
      <div class="filter-container">
        <div class="brand-filter">
          <span>{{brandName}}</span>
          <Dropdown style="margin-left: 20px" @on-click="onBandItemClick">
            <Button type="primary">
              商家选择
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="0" :selected="brandType == 0">全部</DropdownItem>
              <DropdownItem
                :name="item.type"
                :selected="brandType == item.type"
                v-for="(item,index) in brandList"
                :key="index"
              >{{item.name}}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="month-filter">
          <DatePicker
            type="daterange"
            :options="datePickerOption"
            placement="bottom-end"
            placeholder="请选择日期范围,默认全部"
            style="width: 200px"
          ></DatePicker>
        </div>
        <div class="search-container">
          <Input search enter-button placeholder="输入 用户名查询" @on-search="onSearch" />
        </div>
      </div>
      <Table :columns="userColumns" :data="userData" border :max-height="tableMaxHeight"></Table>
      <div
        style="margin: 20px 12px;overflow: hidden;position:fixed;bottom: 10px;right: 10px;width: 100%;"
      >
        <div style="display:flex;flex-direction:row-reverse;">
          <Page
            :total="totalCount"
            :current="currentPage"
            @on-change="changePage"
            :page-size="pageSize"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mixinDevice} from '@/util/mixin';

import Store from '@/store';

const brands = [
  
];

export default {
  mixins:[mixinDevice],
  data() {
    let columnWidth = Store.state.deviceType == 'mobile' ? 140 : 'auto';
    return {
      brandList: brands,
      currentPage: 1,
      totalCount: 1200,
      pageSize: 50,
      brandType: 0,
      datePickerOption: {
        shortcuts: [
          {
            text: "一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: "一月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: "三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          },
          {
            text: "半年",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90 * 2);
              return [start, end];
            }
          },
          {
            text: "一年",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              return [start, end];
            }
          }
        ]
      },
      userColumns: [
        {
          title: "用户名",
          key: "mobile",
          width: columnWidth
        },
        {
          title: "商家名称",
          key: "accountName",
          width: columnWidth
        },
        {
          title: "续费日期",
          key: "renewalTime",
          width: columnWidth
        },
        {
          title: "续费金额",
          key: "renewalPrice",
          width: columnWidth
        },
        {
          title: "注册日期",
          key: "registerTime",
          width: columnWidth
        },
        {
          title: "到期日期",
          key: "expireTime",
          width: columnWidth
        }
      ],
      userData: []
    };
  },
  computed: {
    brandName() {
      if (this.brandType == 0) return "全部";
      this.brandList.forEach(item => {
        if (item.type == this.brandType) {
          return item.name;
        }
      });
      return "未知";
    },
    tableMaxHeight(){
      return this.isMobile() ? 540 : 700;
    }
  },
  methods: {
    onSearch(value) {
      console.log(value);
    },
    onBandItemClick(name) {
      this.brandType = parseInt(name);
    },
    changePage(newPage) {
      this.currentPage = newPage;
      console.log("currentPage: ", this.currentPage);
    }
  },
  mounted() {
   
  }
};
</script>

<style lang="less" scoped>
.ative-list-container {
  .filter-container {
    margin-bottom: 12px;
    padding: 12px 24px;
    border: 1px dashed green;
    display: flex;

    .month-filter {
      margin-left: 12px;
    }
    .search-container {
      margin-left: auto;
    }

    @media screen and (max-width:750px){
      flex-direction: column;
      .month-filter,.search-container{
        margin-top: 10px;
        margin-left: 0;
      }
    }

   
  }
}
</style>